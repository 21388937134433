import logo from './logo.svg';
import Navbar from "./components/Navbar";
import Home from "./pages/Home.js";
import Pictures from "./components/Pictures";
import './App.css';
import { BrowserRouter as Router, Routes ,Route } from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar sticky="top" />
        <Routes>
          <Route path="/" exact component={Home} />
        </Routes>
        <Pictures />
      </Router>
    </div>

  );
}

export default App;