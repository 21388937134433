import logo from "../assets/WG.png";
import { Link } from 'react-router-dom';
import '../styles/Navbar.css';
import ReorderIcon from "@material-ui/icons/Reorder";
import React, { useState } from "react";
import MovingComponent from 'react-moving-text';

function Navbar() {
  const [openLinks, setOpenLinks] = useState(false);

  const toggleNavbar = () => {
    setOpenLinks(!openLinks);
  };
   return (
    <div className="navbar">
      <div className="leftSide" id={openLinks ? "open" : "close"}>
       <Link to="/">
              <MovingComponent
              type="bounce"
              duration="1500ms"
              delay="20 * 100ms"
              direction="normal"
              timing="ease"
              iteration="1"
              fillMode="none">
              <img style={{ width: 200, height: 140 }} src={logo} />
              </MovingComponent>
        </Link>
        <div className="hiddenLinks">
          <Link to="/about"> About Us </Link>
          <Link to="/indoor"> Indoor Play Area </Link>
          <Link to="/events"> Events & Promotions </Link>
          <Link to="/gallery"> Gallery </Link>
          <Link to="/carrer"> Carreer </Link>
          <Link to="/contact"> Contact Us </Link>
        </div>
        <div className="rightSide">
          <Link to="/"> Home </Link>
          <Link to="/about"> About Us </Link>
          <Link to="/indoor"> Indoor Play Area </Link>
          <Link to="/events"> Events & Promotions </Link>
          <Link to="/gallery"> Gallery </Link>
          <Link to="/carrer"> Carreer </Link>
          <Link to="/contact"> Contact Us </Link>
          <MovingComponent
              type="bounce"
              duration="1500ms"
              delay="20 * 100ms"
              direction="normal"
              timing="ease"
              iteration="1"
              fillMode="none">
              <img style={{ width: 200, height: 140 }} src={logo} />
            </MovingComponent>
            <button onClick={toggleNavbar}>
            <ReorderIcon />
          </button>
        </div>
      </div>
    </div>
  )
}

export default Navbar