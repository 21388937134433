import { useEffect, useState } from "react";
import one from '../assets/1.png';
import two from '../assets/2.png';
import three from '../assets/3.png';
import styles from "../styles/pictures.css";
import Typewriter from "typewriter-effect";

const images = [
  one,two,three
];
const headers = [
    "Soft Play Fun Games","Feel and Explore","Adventure Learning"
];
const texts = [
    "Sensory soft play is very popular with children of all ages and needs as soft play provides a safe and fun environment for them to explore and hone their skills.",
    "An interactive play area made for children that combines inspiration, fun and learning through interaction",
    "Adventure learning is about precautions they learn to take before the adventuring variety of activities"
];

export default function Pictures() {
  const [index, setIndex] = useState(0);
  const next = () => {
    setIndex((i) => (i + 1) % images.length);
  };
  useEffect(() => {
    const timer = setInterval(() => {
      next();
    }, 6000);
    return () => {
      clearInterval(timer);
    };
  }, []);
  return (
    <div className="Pictures">
      <article className={styles.article}>
        <picture className={styles.picture}>
        <source media="(min-width: 0px)" srcSet={images[index]} />
        <img style={{ width: 1700, height: 780 }} src={images[index]}  alt="background" />
        </picture>
            <div class='header'>
            <Typewriter
                options={{
                  strings: [headers[index]],
                  autoStart: true,
                  loop: false,
                  cursor: "",
                  deleteSpeed: 10000
                }}
              />
            </div>
      </article>
    </div>
  );
}


